<div class="container-fluid d-none d-lg-block">
  <div class="row">
    <div class="col-12 p-0">

      <ul class="nav navbar-expand-lg bg-secondary">
        <li class="nav-item">
          <a 
            class="border-end border-dark nav-link text-fourth text-decoration-none" 
            [routerLink]="['']">
            Inicio
          </a>
        </li>
        <li class="nav-item">
            <a 
              class="border-end border-dark px-2 nav-link text-fourth text-decoration-none"
              [routerLink]="['branches']">
              Tu Sucursal
            </a>
        </li>
        <li class="nav-item dropdown" ngbDropdown>
          <button class="h-100 btn dropdown-toggle border-end border-dark border-start-0 border-top-0 border-bottom-0 rounded-0 px-2 text-fourth" ngbDropdownToggle>
            Términos y Condiciones
          </button>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li *ngFor="let child of legalMenu">
              <a [href]="child.path" class="dropdown-item">{{ child.name }}</a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown" ngbDropdown>
          <button class="h-100 btn dropdown-toggle border-end border-dark border-start-0 border-top-0 border-bottom-0 rounded-0 px-2 text-fourth" ngbDropdownToggle>
            Regulación
          </button>
          <ul class="dropdown-menu" ngbDropdownMenu>
            <li *ngFor="let child of regulationMenu">
              <a [href]="child.path" class="dropdown-item">{{ child.name }}</a>
            </li>
          </ul>
        </li>

        <li class="nav-item dropdown" ngbDropdown>
          <button class="btn dropdown-toggle text-fourth" ngbDropdownToggle>
            Info. Financiera
          </button>
          <ul class="dropdown-menu" ngbDropdownMenu>

            <li *ngFor="let doc of docs" class="dropdown-submenu" ngbDropdown>
              <button class="dropdown-item d-flex justify-content-between align-items-center" ngbDropdownToggle>
                {{ doc.name }}
              </button>
              <ul class="dropdown-menu" ngbDropdownMenu>
                <li *ngFor="let child of doc.children">
                  <a [href]="child.path" class="dropdown-item">{{ child.name }}</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        
      </ul>

    </div>
  </div>
</div>

<!-- ----------------------------------------------------------------------- -->
<!--                              ANCHOR MOBILE                              -->
<!-- ----------------------------------------------------------------------- -->

<div class="container-fluid bg-white d-block d-lg-none py-2">
  <div class="row">

    <div class="col-4 d-flex align-items-center">
      <button 
        (click)="sideMenuService.menuOpened = true"
        class="btn d-flex align-items-center">
        <i class="material-icons">menu</i>
      </button>
    </div>

    <a 
      class="col-4 text-center d-flex align-items-center"
      href="/">
      <img 
        class="" 
        src="./assets/media/logos/LOGO VERDE CLARO.png" 
        width="100%"
        alt="Logo Porvenir SFP verde">
    </a>

    <div class="col-4">

    </div>

  </div>
</div>

<app-side-menu 
  *ngIf="sideMenuService.menuOpened">
</app-side-menu>