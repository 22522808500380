import { Component } from '@angular/core';
import { SideMenuService } from 'src/app/services/side-menu/side-menu.service';

import { DOCS_DATA, LEGAL_DATA, REGULATION_DATA } from 'src/app/data/docs.data';
import { Doc } from 'src/app/models/doc.model';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent {

  public docs: Array<Doc> = DOCS_DATA;
  public regulationMenu: Doc[] = REGULATION_DATA;
  public legalMenu: Doc[] = LEGAL_DATA;

  constructor(
    public sideMenuService: SideMenuService
  ) {

  }

}
