<div class="container py-5">
  <div class="row">
    <div class="col-12 text-justify">
      <h2 class="text-third mb-4">
        <b>Unidad Especializada de Atención a Usuarios (UNE)</b>
      </h2>
    </div>

    <div class="col-12 mb-4">
      En caso de consulta, reclamación o aclaración podrá presentarla a la Unidad Especializada de Atención a Usuarios (UNE), la que dará respuesta en un plazo no mayor a 30 días hábiles.
    </div>

    <div class="col-12 mb-4">
      Ponemos a disposición del cliente información del titular de Unidad Especializada de Atención a Usuario.
    </div>

    <div class="col-12">
      <b>Titular: </b> <span>Viridiana Sánchez Mabridis </span>
    </div>
    <div class="col-12 mb-3">
      <b>Dirección: </b> <span>RUIZ CORTINES MZ1 LT 20 NO. 22, COLONIA LOMAS DE ATIZAPAN 2A SECCION. C.P. 52977 ALCALDÍA/MUNICIPIO: ATIZAPAN ENTIDAD FEDERATIVA: MÉXICO, REFERENCIA: FRENTE PLAZA GALERIAS ATIZAPAN ENTRE CALLE DR JIMENEZ CANTU Y CALLE ADOLFO RUIZ CORTINEZ</span>
    </div>
    <div class="col-12 mb-3">
      <b>Teléfono: </b> <span>(55) 5266 5387</span>
    </div>
    <div class="col-12">
      <b>Horario de Atención: </b> <span>Lunes a Viernes de 09:00 a 18:00 hrs.</span>
    </div>
    <div class="col-12">
      <b>Correo electrónico: </b> <span>viridiana.sanchez@porvenirsofipo.com.mx</span>
    </div>

  </div>
</div>