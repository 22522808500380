<div class="container-fluid">
    <div class="row">

        <div 
            class="col-12 bg-center my-4 d-flex align-items-center justify-content-center" 
            style="background-image: url('/assets/media/img/abarrotes.jpg'); height: 35vh;">
            <div class="row bg-white rounded-5 py-2 px-0">

                <div 
                    class="d-block text-center px-0 fw-200"
                    style="font-size: calc(0.7em + 0.7vh)">
                    Conoce nuestro producto
                </div>

                <div 
                    class="d-block text-center text-fifth px-0"
                    style="font-size: calc(1.2em + 1.2vh)">
                    <b>"Crédito personal para personas físicas con negocios"</b>
                </div>

            </div>
        </div>

    </div>
</div>

<div class="container px-5 mb-5">
    <div class="row">

        <div 
            class="col-12 text-fifth text-center mb-3"
            style="font-size: calc(2em + 2vh)">
            <b>Requisitos para tramitar tu préstamo</b>
        </div>
        <div 
            class="col-12"
            style="font-size: calc(0.8em + 0.8vh);">
            <div class="row">

                <div class="col-12 col-md-6 col-lg-3">
                    <img 
                        src="/assets/media/icons/INE.png" 
                        alt="Ícono de INE verde"
                        class="mx-auto d-block"
                        width="70%">
                    <div class="text-uppercase text-center px-0">
                        <b>Identificación oficial vigente</b>
                    </div>
                    <div class="text-center mb-3">
                        INE/Pasaporte
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <img 
                        src="./assets/media/icons/COMPROBANTE-DE-DOMICILIO.png" 
                        alt="Ícono de comprobante de domicilio verde"
                        class="mx-auto d-block"
                        width="70%">
                    <div class="text-uppercase fw-bold text-center mb-3">
                        Comprobante de domicilio
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <img src="./assets/media/icons/REFERENCIAS.png" 
                    alt="Ícono de referencias verde"
                    class="mx-auto d-block"
                    width="70%">
                    <div class="text-uppercase text-center fw-bold mb-3">
                        Teléfono de referencias personales
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <img 
                        src="./assets/media/icons/PRESOLICITUD.png" 
                        alt="Ícono de pre solicitud verde"
                        class="mx-auto d-block" 
                        width="70%">
                    <div class="text-uppercase text-center fw-bold mb-3">
                        Llenar pre solicitud
                    </div>
                </div>

            </div>
        </div>

        <div class="col-12 text-center text-secondary">
            <b>COMISIONES </b>0%: Apertura [0%], Pago tardio: [0%], reclamación improcedente: [0%], Cobranza [0%]
        </div>

    </div>
</div>