import { Component } from '@angular/core';

@Component({
  selector: 'app-costs-and-commissions-page',
  templateUrl: './costs-and-commissions-page.component.html',
  styleUrls: ['./costs-and-commissions-page.component.scss']
})
export class CostsAndCommissionsPageComponent {

}
