<div 
    class="container-fluid position-fixed bg-transparent-dark" 
    style="top: 0px; left: 0px; height: 100vh; z-index: 1024 !important"
>
    <div class="row h-100">

        <div class="col-7 col-sm-7 col-md-4 col-lg-3 col-xl-2 h-100 bg-white pt-4 px-0 mb-5" style="height: 100vh;">
            <div class="row">
                <div class="col-12 pt-3">
                    <div class="row">

                        <div class="col-12 text-center mb-4">
                           <img class="" src="./assets/media/logos/LOGO VERDE CLARO.png" alt="Logo Porvenir SFP verde" width="60%">
                        </div>
          
                        <div class="col-12 px-3 py-2">
                            <a routerLink="/" 
                            (click)="sideMenuService.menuOpened = false" 
                            class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                                <b>Inicio</b>
                                <i class="material-icons ms-auto" style="font-size: 15px !important;">arrow_forward_ios</i>
                            </a> 
                        </div>

                        <div class="col-12 px-3 py-2">
                            <a routerLink="/branches" 
                            (click)="sideMenuService.menuOpened = false" 
                            class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                                <b>Tu sucursal</b>
                                <i class="material-icons ms-auto" style="font-size: 15px !important;">arrow_forward_ios</i>
                            </a> 
                        </div>

                        <!-- <div class="col-12 px-3 py-2">
                           <a routerLink="/terms/privacy-notice" 
                           (click)="sideMenuService.menuOpened = false" 
                           class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                               <b>Aviso privacidad</b>
                               <i class="material-icons ms-auto" style="font-size: 15px !important;">arrow_forward_ios</i>
                           </a> 
                       </div> -->

                       <div class="col-12 px-3 py-2">
                            <a
                                (click)="collapseLegal.toggle()"
                                [attr.aria-expanded]="!isLegalCollapsed"
                                class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                                <b>Términos y Condiciones</b>
                                <i 
                                    class="material-icons ms-auto">
                                    {{!isLegalCollapsed ? 'keyboard_arrow_down' : 'navigate_next'}}
                                </i>
                            </a> 

                            <div 
                                #collapseLegal="ngbCollapse" 
                                [(ngbCollapse)]="isLegalCollapsed"
                                class="col-12 ps-3 py-2">
                                <a
                                    *ngFor="let child of legalMenu"
                                    href="{{child.path}}"
                                    class="text-decoration-none text-primary btn btn-link w-100 text-start">
                                    {{child.name}}
                                </a>
                            </div>
                        </div>

                       <div class="col-12 px-3 py-2">
                            <a
                                (click)="collapseRegulation.toggle()"
                                [attr.aria-expanded]="!isRegulationCollapsed"
                                class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                                <b>Regulación</b>
                                <i 
                                    class="material-icons ms-auto">
                                    {{!isRegulationCollapsed ? 'keyboard_arrow_down' : 'navigate_next'}}
                                </i>
                            </a> 

                            <div 
                                #collapseRegulation="ngbCollapse" 
                                [(ngbCollapse)]="isRegulationCollapsed"
                                class="col-12 ps-3 py-2">
                                <a
                                    *ngFor="let child of regulationMenu"
                                    href="{{child.path}}"
                                    class="text-decoration-none text-primary btn btn-link w-100 text-start">
                                    {{child.name}}
                                </a>
                            </div>
                        </div>

                       <div class="col-12 px-3 py-2">
                            <a
                                (click)="collapse.toggle()"
                                [attr.aria-expanded]="!isCollapsed"
                                class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                                <b>Info. financiera</b>
                                <i class="material-icons ms-auto" style="font-size: 15px !important;">arrow_forward_ios</i>
                            </a> 

                            <div 
                                #collapse="ngbCollapse" 
                                [(ngbCollapse)]="isCollapsed"
                                class="col-12 ps-3 py-2">
                                <div *ngFor="let doc of docs" class="w-100">
                                <a
                                    (click)="toggleSubmenu(doc)"
                                    class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                                    {{doc.name}}
                                    <i 
                                        class="material-icons ms-auto" 
                                        *ngIf="doc.children && doc.children.length > 0">
                                        {{doc.isOpen ? 'keyboard_arrow_down' : 'navigate_next'}}
                                    </i>
                                </a>
                                <div *ngIf="doc.isOpen" class="ps-3">
                                    <a
                                    *ngFor="let child of doc.children"
                                    href="{{child.path}}"
                                    target="_blank"
                                    class="text-decoration-none text-primary btn btn-link w-100 d-flex justify-content-between align-items-center">
                                    {{child.name}}
                                    </a>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <a class="col-5 col-sm-5 col-md-8 col-lg-9 col-xl-10" (click)="sideMenuService.menuOpened = false">
    
        </a>
    
    </div>
</div>