<div class="container py-5">
  <div class="row">
    <div class="col-12 text-justify">
      <h2 class="text-third mb-4">
        <b>Costos y Comisiones</b>
      </h2>
    </div>

    <div 
      class="col-12 mb-5"
      style="font-size: calc(0.8rem + 0.8vh)">
      <b>Te damos transparencia y <span class="text-third">claridad</span></b>
    </div>

    <div class="col-12">
      <b>Concepto de comisión</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>0% N/A</b>
    </div>

    <div class="col-12">
      <b>Monto de comisión</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>0% N/A</b>
    </div>

    <div class="col-12">
      <b>Periodicidad de comisión</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>0% N/A</b>
    </div>

    <div class="col-12">
      <b>Apertura</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>0% N/A</b>
    </div>

    <div class="col-12">
      <b>Reclamación improcedente</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>0% N/A</b>
    </div>

    <div class="col-12">
      <b>Cobranza</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>0% N/A</b>
    </div>

  </div>
</div>