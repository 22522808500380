import { Component } from '@angular/core';
import { SideMenuService } from 'src/app/services/side-menu/side-menu.service';

import { DOCS_DATA, LEGAL_DATA, REGULATION_DATA } from 'src/app/data/docs.data';
import { Doc } from 'src/app/models/doc.model';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {

  public docs: Array<Doc> = DOCS_DATA;
  public regulationMenu: Doc[] = REGULATION_DATA;
  public legalMenu: Doc[] = LEGAL_DATA;
  public isCollapsed = true;
  public isRegulationCollapsed = true;
  public isLegalCollapsed = true;

  constructor(
    public sideMenuService: SideMenuService
  ) {

  }

  toggleSubmenu(doc: any) {
    doc.isOpen = !doc.isOpen;
  }

}
