<div 
    class="container-fluid bg-center px-3 px-md-5 py-5" 
    style="background-image: url('./assets/media/banners/PORVENIR-SOFIPO-PRESTAMOS-PARA-TU-NEGOCIO_WEB.png');">
    <div class="row">
        <div class="col-12 mb-5 ">
            <div class="row justify-content-end">
                <div class="col-9 col-md-6 col-lg-4">
                    <img class="" src="./assets/media/img/LOGOTIPO BCO.png" alt="Logo Porvenir SFP blanco" width="100%">
                </div>
                <div 
                    class="col-12 text-white text-end"
                    style="font-size: calc(1rem + 1vh);">
                    SFP Porvenir S.A. De C.V. SFP
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-end">
        <div class="col-12 col-md-6 col-lg-5 mt-5 mb-1 pb-4">
            <div class="">
                <h1>
                    <b 
                        class="text-white d-none d-md-block" 
                        style="font-size: calc(1.2em + 1.2vh)">
                        Crédito personal para personas físicas con negocios.
                    </b>

                    <b 
                        class="text-white d-block d-md-none" 
                        style="font-size: calc(1.5em + 1.5vh)">
                        ¡Tu buen historial
                        te recompensa!
                    </b>
                </h1>
            </div>
        </div>
    </div>
    <div class="row justify-content-end">
        <div class="col-12 col-md-6 col-lg-5 mb-4">
            <p 
                class="text-white fw-200" 
                style="font-size: calc(1em + 1vh)">
                Préstamos para fortalecer tu negocio.
            </p>
        </div>
    </div>

    <div class="row my-5 justify-content-end">
        <div class="col-12 col-lg-6 text-center my-5">
            <button 
                class="btn bg-primary text-white rounded-3 px-4"
                style="font-size: calc(1em + 1vh);">
                CONTÁCTANOS
            </button>
        </div>
    </div>
</div>