import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

import { HomePageComponent } from './pages/home-page/home-page.component';
import { BranchesPageComponent } from './pages/branches-page/branches-page.component';
import { PrivacyNoticePageComponent } from './pages/privacy-notice-page/privacy-notice-page.component';
import { CondusefPageComponent } from './pages/regulations/condusef-page/condusef-page.component';
import { FinancialInstitutionsBureauPageComponent } from './pages/regulations/financial-institutions-bureau-page/financial-institutions-bureau-page.component';
import { CatPageComponent } from './pages/cat-page/cat-page.component';
import { UnePageComponent } from './pages/une-page/une-page.component';
import { CostsAndCommissionsPageComponent } from './pages/costs-and-commissions-page/costs-and-commissions-page.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        component: HomePageComponent
      },
      {
        path: 'branches',
        component: BranchesPageComponent
      },
      {
        path: 'cat',
        component: CatPageComponent
      },
      {
        path: 'costs-and-commissions',
        component: CostsAndCommissionsPageComponent
      },
      {
        path: 'terms/privacy-notice',
        component: PrivacyNoticePageComponent
      },
      {
        path: 'une',
        component: UnePageComponent
      },
      {
        path: 'regulations',
        children: [
          {
            path: 'condusef',
            component: CondusefPageComponent
          },
          {
            path: 'financial-institutions-bureau',
            component: FinancialInstitutionsBureauPageComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
