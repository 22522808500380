import { Component } from '@angular/core';

@Component({
  selector: 'app-une-page',
  templateUrl: './une-page.component.html',
  styleUrls: ['./une-page.component.scss']
})
export class UnePageComponent {

}
