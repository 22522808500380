<div class="container py-5">
  <div class="row">
    <div class="col-12 text-justify">
      <h2 class="text-third mb-4">
        <b>Costo Anual Total (CAT)</b>
      </h2>
    </div>

    <div 
      class="col-12 mb-5"
      style="font-size: calc(0.8rem + 0.8vh)">
      <b>Te damos transparencia y <span class="text-third">claridad</span></b>
    </div>

    <div class="col-12">
      <b>CAT Promedio</b>
    </div>
    <div class="col-12 text-third">
      <b>144.07% sin IVA</b>
    </div>
    <div class="col-12 text-secondary mb-4">
      <small>(CAT: Costo anual Total). Para fines comparativos y de información.</small>
    </div>

    <div class="col-12">
      <b>Tasa Promedio ponderada anual fija</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>78.91% sin IVA</b>
    </div>

    <div class="col-12">
      <b>Concepto de comisión</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>0% N/A</b>
    </div>

    <div class="col-12">
      <b>Monto de comisión</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>$0 N/A</b>
    </div>

    <div class="col-12">
      <b>Periodicidad de comisión</b>
    </div>
    <div class="col-12 text-third mb-4">
      <b>0 N/A</b>
    </div>

    <div class="col-12 text-secondary mb-4">
      <small>
        Vigencia de la oferta al 01 de julio de 2024. Estos valores son para fines informativos y de comparación exclusivamente. CAT calculado el 20 de octubre de 2024.
      </small>
    </div>

    <div class="col-12">
      <b>información Importante</b>
    </div>

    <div class="col-12">
      <ul>

        <li>
          Incumplir tus obligaciones te puede generar comisiones e intereses moratorios.
        </li>

        <li>
          Contratar créditos por que excedan tu capacidad de pago afectar tu historial crediticio. 
        </li>

      </ul>
    </div>

  </div>
</div>